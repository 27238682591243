<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
  <v-row dense>
      <v-col >
        <v-text-field dense label="Substância" v-model="currentItem.nome_substancia" outlined :rules="requiredField" />
      </v-col>
      <v-col >
        <v-autocomplete dense label="Forma Farmacêutica" v-model="currentItem.forma_farmaceutica" :items="forma_farmaceutica" item-text="nome" item-value="id" outlined :rules="requiredField" :loading="isLoadingGeral" return-object />
      </v-col>
      <v-col >
        <v-autocomplete dense label="Tarja" v-model="currentItem.tarja" :items="tarja" item-text="nome" item-value="id" outlined :rules="requiredField" :loading="isLoadingGeral"/>
      </v-col>
      <!-- <v-col >
        <v-autocomplete dense label="Tipologia" v-model="currentItem.tipologia" :items="tipologia" item-text="nome" item-value="id" outlined :rules="requiredField" :loading="isLoadingGeral"/>
      </v-col> -->
       <v-col >
        <v-autocomplete dense :items="statusOptions" label="Manipulado" v-model="currentItem.manipulado" item-text="label" item-value="value" outlined :rules="requiredField"  />
      </v-col>
    </v-row>
    <v-row dense>
       <v-col cols="2">
        <v-autocomplete dense label="Classificação" v-model="currentItem.classificacao" :items="classificacao" item-text="nome" item-value="id" outlined :loading="isLoadingGeral" :rules="requiredField" return-object />
      </v-col>
        <v-col cols="2">
          <v-autocomplete dense label="Classe Terapêutica" v-model="currentItem.classe_terapeutica" :items="classeTerapeurica" item-text="nome" item-value="id" outlined :loading="isLoadingGeral" clearable />
        </v-col>
      <v-col cols="2">
        <v-autocomplete dense :items="statusOptions" label="Item Padrão" v-model="currentItem.padrao" item-text="label" item-value="value" outlined :rules="requiredField"  />
      </v-col>
      <v-col cols="2">
        <v-autocomplete dense :items="statusOptions" label="Alto Custo" v-model="currentItem.alto_custo" item-text="label" item-value="value" outlined :rules="requiredField"  />
      </v-col>
       <v-col >
         <v-autocomplete  label="Via de Administração" :item-text="viaAdministracaoText" v-model="selectedViaAdministracao" :items="via_administracao" item-value="id" outlined :rules="requiredField" :loading="isLoadingGeral" multiple chips >
           <template v-slot:selection="{ item, index }">
             <v-chip v-if="item" :key="index" >
               {{ viaAdministracaoText(item) }}
             </v-chip>
           </template>
         </v-autocomplete>
       </v-col>
       <v-col cols="2">
        <v-autocomplete dense label="Via Sonda" v-model="currentItem.via_sonda" :items="via_sonda" item-text="nome" item-value="id" outlined :loading="isLoadingGeral" :rules="requiredField" return-object />
      </v-col>
    </v-row>
    <v-row >
      <v-col v-if="currentItem?.via_sonda?.id === 1">
        <v-text-field dense label="Como devemos Administrar Via Sonda?" v-model="currentItem.obs_via_sonda" outlined :rules="obsViaSondaRules"/>
      </v-col>
      <v-col  v-if="currentItem?.classificacao?.id === 2">
        <v-autocomplete dense label="Livro" v-model="currentItem.livro" :items="livro" :item-text="livroText" item-value="id" outlined :loading="isLoadingGeral" clearable />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col >
        <v-autocomplete dense label="Apresentação para armazenamento / distrubuição" :item-text="apresentacaoText" v-model="currentItem.unidade_medida_estoque" :items="unidade_medida_estoque"  item-value="id" outlined :rules="requiredField" :loading="isLoadingGeral"/>
      </v-col>
      <v-col >
        <v-autocomplete dense label="Unidade de medida para prescrição" :item-text="unidadeText" v-model="currentItem.apresentacao_prescricao" :items="apresentacao"  item-value="id" outlined :rules="requiredField" :loading="isLoadingGeral"/>
      </v-col>
      <v-col>
        <v-text-field dense label="Quantidade de unidade de medida da apresentação" v-model="currentItem.qtd_unidade_medica_estoque" type="Number" outlined  :rules="requiredField"></v-text-field>
      </v-col>
       <v-col >
        <v-autocomplete dense label="Unidade de cobrança" v-model="currentItem.unidade_medida_cobranca" :item-text="unidadeMedidacobrancaText" :items="unidade_medida_cobranca" item-value="id" outlined :rules="requiredField" :loading="isLoadingGeral" return-object/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-autocomplete dense :items="statusOptions" label="Alto Risco" v-model="currentItem.alto_risco" item-text="label" item-value="value" outlined :rules="requiredField"  />
      </v-col>
      <v-col cols="2">
        <v-autocomplete dense :items="statusOptions" label="Risco de Interação" v-model="currentItem.interacao" item-text="label" item-value="value" outlined :rules="requiredField"  />
      </v-col>
      <v-col cols="2">
        <vuetify-money dense label="Custo estimados " v-model="currentItem.custo" outlined :options="options"/>
      </v-col>
      <v-col cols="2">
        <v-text-field  dense  label="Precificação Sugerida"  :value="getPrecoPrecificacao()"  outlined  readonly disabled />
      </v-col>
       <v-col cols="3">
        <v-autocomplete dense :items="armazenamentoOptions" label="Temperatura Armazenamento" v-model="currentItem.armazenamento" item-text="label" item-value="value" outlined :rules="requiredField"  />
      </v-col>
      <v-col >
        <v-autocomplete dense label="Endereço" v-model="currentItem.enderecos" :items="endereco" :item-text="enderecoText" item-value="id" outlined :loading="isLoadingGeral"  :rules="requiredField"  multiple chips/>
      </v-col>
    </v-row>
    <v-row  v-if="currentItem.alto_risco">
      <v-col>
        <v-text-field dense label="Qual o Risco para o Paciente?" v-model="currentItem.qual_risco" outlined />
      </v-col>
    </v-row>
    <v-row  v-if="currentItem.interacao">
      <v-col >
        <v-text-field dense label="Qual o Risco de Interação?" v-model="currentItem.obs_interacao" outlined />
      </v-col>
    </v-row>
    <v-row dense>
       <v-col >
        <v-autocomplete dense label="Tabela de Preço Medicamento" v-model="currentItem.tabela_medicamento" :items="brasindice" :item-text="brasindiceText" item-value="id" outlined :loading="isLoadingBrasindice" return-object/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
        <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
        <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row class="justify-end mt-0" dense>
      <v-col class="text-end">
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';

export default {
  name: "EditarMedicamento",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      requiredField: [e => (e !== null && e !== undefined && e !== '' ? true : 'Obrigatório')],
      isLoadingBrasindice: false,
      isLoadingGeral: false,
      tipo: [],
      classificacao: [],
      apresentacao: [],
      tarja: [],
      // tipologia: [],
      unidade_medida_estoque: [],
      via_administracao: [],
      selectedViaAdministracao: [],
      forma_farmaceutica: [],
      via_sonda: [],
      classeTerapeurica: [],
      unidade_medida_cobranca: [],
      livro: [],
      endereco: [],
      brasindice: [],
      statusOptions: [
        { label: "Sim", value: true },
        { label: "Não", value: false }
      ],
      statusOptionsEspecial: [
        { label: "Sim", value: true },
        { label: "Não", value: false },
        { label: "Não se Aplica", value: null }
      ],
       options: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2,
      },
      armazenamentoOptions: [
        { label: "2ºC a 8ºC - Refrigerado", value: true },
        { label: "15ºC a 30ºC - Temperatura ambiente", value: false },
      ],
      originalObsViaSonda: null,
      originalQualRisco: null,
    })
  },
  components: { ConfirmButton },
  methods: {
    localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) return;

      // Checagem manual para garantir que os campos obrigatórios estão preenchidos
      if (this.currentItem.via_sonda?.id === 1 && !this.currentItem.obs_via_sonda) {
        this.$toast.error('O campo "Como devemos Administrar Via Sonda?" é obrigatório.');
        return;
      }

      if (this.currentItem.alto_risco === true && !this.currentItem.qual_risco) {
        this.$toast.error('O campo "Qual o Risco para o Paciente?" é obrigatório.');
        return;
      }

      if (this.currentItem?.classificacao?.id === 2 && !this.currentItem.livro) {
        this.$toast.error('A escolha do campo "Livro" é obrigatório quando a classificação for Medicamento Controlado.');
        return;
      }

      const fields = {
        id: this.currentItem.id,
        nome_substancia: this.currentItem.nome_substancia,
        forma_farmaceutica: this.currentItem.forma_farmaceutica ? this.currentItem.forma_farmaceutica.id : null,
        tarja: this.currentItem.tarja ? this.currentItem.tarja.id || this.currentItem.tarja : null,
        // tipologia: this.currentItem.tipologia ? this.currentItem.tipologia.id || this.currentItem.tipologia : null,
        manipulado: this.currentItem.manipulado,
        classificacao: this.currentItem.classificacao ? this.currentItem.classificacao.id || this.currentItem.classificacao : null,
        classe_terapeutica : this.currentItem.classe_terapeutica ? this.currentItem.classe_terapeutica.id || this.currentItem.classe_terapeutica : null,
        livro: this.currentItem?.classificacao?.id === 2 ? this.currentItem.livro.id || this.currentItem.livro : null,
        padrao: this.currentItem.padrao,
        via_administracao: this.selectedViaAdministracao,
        via_sonda: this.currentItem.via_sonda ? this.currentItem.via_sonda.id : null,
        obs_via_sonda: this.currentItem.obs_via_sonda,
        unidade_medida_estoque: this.currentItem.unidade_medida_estoque ? this.currentItem.unidade_medida_estoque.id || this.currentItem.unidade_medida_estoque : null,
        apresentacao_prescricao: this.currentItem.apresentacao_prescricao ? this.currentItem.apresentacao_prescricao.id || this.currentItem.apresentacao_prescricao : null,
        qtd_unidade_medica_estoque: this.currentItem.qtd_unidade_medica_estoque,
        alto_risco: this.currentItem.alto_risco,
        qual_risco: this.currentItem.qual_risco,
        custo: this.currentItem.custo ? this.currentItem.custo : 0,
        armazenamento: this.currentItem.armazenamento,
        enderecos: this.currentItem.enderecos ? this.currentItem.enderecos.id :  null,
        alto_custo: this.currentItem.alto_custo,
        interacao: this.currentItem.interacao,
        obs_interacao: this.currentItem.obs_interacao,
        tabela_medicamento: this.currentItem.tabela_medicamento ? this.currentItem.tabela_medicamento.id : null,
        unidade_medida_cobranca: this.currentItem.unidade_medida_cobranca ? this.currentItem.unidade_medida_cobranca.id : null,
        ativo: this.currentItem.ativo
      };

      // console.log("Enviando =>", fields);
      this.onSubmit(fields);
    },
    async getTipoMatMedBase() {
      this.isLoadingGeral = true
      try {
        const { data } = await api.get("/matmed/tipo/");
        this.tipo = data
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingGeral = false
      }
    },
    async getBrasindice() {
      this.isLoadingBrasindice = true
      try {
        const { data } = await api.get("/matmed/brasindice/");
        this.brasindice = data
          .filter(item => item.ativo && !item.dieta) // Filtra os ativos que não são dieta
          .sort((a, b) => a.descricao.localeCompare(b.descricao)); // Ordena pela descrição
        // console.log(this.brasindice)
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingBrasindice = false
      }
    },
    async getClassificacao() {
      this.isLoadingGeral = true
      try {
        const { data } = await api.get("/matmed/classificacao/");
        this.classificacao = data
          .filter(item => item.ativo && [1, 2, 4, 5, 8, 10].includes(item.id))
          .sort((a,b) => a.nome.localeCompare(b.nome));
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingGeral = false
      }
    },
    async getApresentacao() {
      this.isLoadingGeral = true
      try {
        const { data } = await api.get("/matmed/apresentacao/");
        this.apresentacao = data
          .filter(item => item.ativo)
          .sort((a,b) => a.nome.localeCompare(b.nome));
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingGeral = false
      }
    },
    async getTarja() {
      this.isLoadingGeral = true
      try {
        const { data } = await api.get("/matmed/tarja/");
        this.tarja = data
          .filter(item => item.ativo)
          .sort((a,b) => a.nome.localeCompare(b.nome));
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingGeral = false
      }
    },
    // async getTipologia() {
    //   this.isLoadingGeral = true
    //   try {
    //     const { data } = await api.get("/matmed/tipologia/");
    //     this.tipologia = data
    //       .filter(item => item.ativo)
    //       .sort((a,b) => a.nome.localeCompare(b.nome));
    //   }
    //   catch (error) {
    //     this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
    //   } finally {
    //     this.isLoadingGeral = false
    //   }
    // },
    async getViaAdministracao() {
      this.isLoadingGeral = true
      try {
        const { data } = await api.get("/matmed/via_administracao/");
        this.via_administracao = data
          .filter(item => item.ativo)
          .sort((a,b) => a.nome.localeCompare(b.nome));
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingGeral = false
      }
    },
    async getUnidadeMedida() {
      this.isLoadingGeral = true
      try {
        const { data } = await api.get("/uteis/unidade/");
        this.unidade_medida_estoque = data
          .filter(item => item.ativo)
          .sort((a,b) => a.nome.localeCompare(b.nome));
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingGeral = false
      }
    },
     async getViaSonda() {
      this.isLoadingGeral = true
      try {
        const { data } = await api.get("/matmed/aplicavel/");
        this.via_sonda = data
          .filter(item => item.ativo)
          .sort((a,b) => a.nome.localeCompare(b.nome));
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingGeral = false
      }
    },
    async getFormafarmaceutica() {
        this.isLoadingGeral = true
        try {
          const { data } = await api.get("/matmed/forma_farmaceutica/");
          this.forma_farmaceutica = data
            .filter(item => item.ativo)
            .sort((a,b) => a.nome.localeCompare(b.nome));
        }
        catch (error) {
          this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
        } finally {
          this.isLoadingGeral = false
        }
    },
    async getClasseTerapeutica() {
      this.isLoadingGeral = true
      try {
        const { data } = await api.get("/matmed/classe_terapeutica/");
        this.classeTerapeurica = data
          .filter(item => item.ativo)
          .sort((a,b) => a.nome.localeCompare(b.nome));
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingGeral = false
      }
    },
    async getLivro() {
      this.isLoadingGeral = true
      try {
        const { data } = await api.get("/matmed/livro/");
        this.livro = data
          .filter(item => item.ativo)
          .sort((a,b) => a.codigo.localeCompare(b.codigo));
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingGeral = false
      }
    },
    async getEndereco() {
      this.isLoadingGeral = true
      try {
        const { data } = await api.get("/matmed/enderecos/");
        this.endereco = data
          .filter(item => item.ativo)
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingGeral = false
      }
    },
    async getUnidadeMedidacobranca() {
      this.isLoadingGeral = true
      try {
        const { data } = await api.get("/uteis/unidade/");
        this.unidade_medida_cobranca = data
          .filter(item => item.ativo)
          .sort((a,b) => a.nome.localeCompare(b.nome));
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingGeral = false
      }
    },
    getPrecoPrecificacao() {
      if (!this.currentItem) return "R$ 0,00"; // Evita erro se `currentItem` não estiver carregado

      const custo = parseFloat(this.currentItem?.custo) || 0;
      const percentualCusto = parseFloat(this.currentItem?.custo_empresa) || 0;
      const resultadoCusto = custo * (1 + percentualCusto / 100);

      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(resultadoCusto);
    },

  },
  computed:{
    apresentacaoText() {
      return (item) => {
          return `${item.nome} - ${item.termo}`;
      }
    },
    unidadeMedidacobrancaText() {
      return (item) => {
          return `${item.codigo} - ${item.nome}  - ${item.termo}`;
      }
    },
    enderecoText() {
      return (item) => {
          return `Rua: ${item.rua} - Prédio: ${item.predio} - APT: ${item.apt}`;
      }
    },
    unidadeText() {
      return (item) => {
          return `${item.nome} - ${item.abreviatura}`;
      }
    },
    viaAdministracaoText() {
      return (item) => {
          return `${item.nome} - ${item.sigla} `;
      }
    },
    brasindiceText() {
      return (item) => {
        const precoUnitarioFAB = (parseFloat(item.preco_fab_embalagem) || 0) / (parseFloat(item.qtd_embalagem) || 1);
        const precoUnitarioPMC = (parseFloat(item.preco_pmc_embalagem) || 0) / (parseFloat(item.qtd_embalagem) || 1);

        // Formata o valor para R$ XX,XX
        const precoFormatado = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(precoUnitarioFAB);
        const precoFormatadoPMC = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(precoUnitarioPMC);
          return `${item.descricao} - ${item.complemento_descricao} - Fabricante ${item.fabrica} - Cód. TUSS ${item.cod_TUSS ? item.cod_TUSS : 'Não Cadastrado'} - Cód. Brasindice ${item.cod_brasindice.padStart(8, '0')} - Preço FAB UND R$ ${precoFormatado} ${item.restrito_hospitalar ? " - RESTRITO HOSPITALAR": ""} - Preço PMC UND R$ ${precoFormatadoPMC}`;
      }
    },
    livroText() {
      return (item) => {
          return `${item.codigo} - ${item.descricao} - ${item.substancia} ${item.observacao ? ` - ${item.observacao}` : ''}`;
      }
    },
    viaAdministracaoSync: {
      get() {
        return this.currentItem.via_administracao
          ? this.currentItem.via_administracao.map(via => via.id)
          : [];
      },
      set(value) {
        this.selectedViaAdministracao = value;
      }
    },
     // Regra para "Como devemos Administrar Via Sonda?"
    obsViaSondaRules() {
      return this.currentItem.via_sonda?.id === 1 
        ? [e => (e !== null && e !== undefined && e !== '' ? true : 'Campo obrigatório quando "Via Sonda" for "Sim".')] 
        : [];
    },
    // Regra para "Qual o Risco para o Paciente?"
    qualRiscoRules() {
      return this.currentItem.alto_risco === true
        ? [e => (e !== null && e !== undefined && e !== '' ? true : 'Campo obrigatório quando "Alto Risco" for "Sim".')] 
        : [];
    }
  },
  mounted() {
    this.getTipoMatMedBase()
    this.getClassificacao()
    this.getApresentacao()
    this.getTarja()
    // this.getTipologia()
    this.getUnidadeMedida()
    this.getViaAdministracao()
    this.getFormafarmaceutica()
    this.getViaSonda()
    this.getClasseTerapeutica()
    this.getLivro()
    this.getEndereco()
    this.getBrasindice()
    this.getUnidadeMedidacobranca()
    if (this.currentItem.via_administracao) {
    this.selectedViaAdministracao = this.currentItem.via_administracao.map(via => via.id);
  }

    // Armazena os valores iniciais
    this.originalObsViaSonda = this.currentItem.obs_via_sonda;
    this.originalQualRisco = this.currentItem.qual_risco;
    this.currentItem.ativo = Number(this.currentItem.ativo)
  },
  watch: {
    'currentItem.via_sonda': {
      handler(newValue, oldValue) {
        if (oldValue === null && newValue?.id !== 2 && newValue?.id !== 3) {
          // Armazena o valor original quando carregado pela primeira vez
          this.originalObsViaSonda = this.currentItem.obs_via_sonda;
        }

        if (newValue?.id === 2 || newValue?.id === 3) {
          // Limpa o campo se via_sonda for 2 ou 3
          this.currentItem.obs_via_sonda = null;
        } else if (oldValue?.id === 2 || oldValue?.id === 3) {
          // Se o usuário voltar para outro valor, restaura o original
          this.currentItem.obs_via_sonda = this.originalObsViaSonda;
        }
      },
      deep: true,
    },
    'currentItem.alto_risco': {
      handler(newValue, oldValue) {
        if (oldValue === null && newValue !== false) {
          // Armazena o valor original quando carregado pela primeira vez
          this.originalQualRisco = this.currentItem.qual_risco;
        }

        if (newValue === false) {
          // Limpa o campo se alto_risco for false
          this.currentItem.qual_risco = null;
        } else if (oldValue === false) {
          // Se o usuário voltar para true, restaura o valor original
          this.currentItem.qual_risco = this.originalQualRisco;
        }
      },
      deep: true,
    },
  },  


}
</script>
